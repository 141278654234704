import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        台湾出身。日本にある美味しい台湾料理を巡ることが大好き。<br />
        主にCGデザイナーとして活動。<br />
        空間演出や映像制作、環境デザインなど、クリエイティブなプロジェクトに携わる。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
